<template>
  <div>
    <open-card v-if="openData.count" />
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Servis Bilgileri</span>
        </template>
        <validation-observer ref="simpleRules">
          <service-form
            :submit-form="submitForm"
            :submit-status="submitStatus"
          />
        </validation-observer>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="this.$route.params.id_customers" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ServiceForm from '@/views/Admin/Services/ServiceForm.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OpenCard from '@/views/Admin/Services/OpenCard.vue'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    ServiceForm,
    CustomerInfo,
    CustomerInvoice,
    CustomerHistory,
    OpenCard,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
    openData() {
      return this.$store.getters['services/openData']
    },
    saveData() {
      return this.$store.getters['services/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push(`/app/services/view/${val.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('services/RESET_DATA_ITEM')
    this.$store.commit('customerMachines/RESET_DATA_ITEM')
    this.getCustomer()
    this.getOpenData()
    localize('tr')
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/getDataItem', this.$route.params.id_customers)
      this.dataItem.id_customers = this.$route.params.id_customers
    },
    getOpenData() {
      this.$store.dispatch('services/openControl', { id_customers: this.$route.params.id_customers })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.dataItem.id_customers = this.$route.params.id_customers
          this.$store.dispatch('services/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
